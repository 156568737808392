<template>
  <v-container class="stats-container" fluid>
    <v-row justify="center">
      <v-col cols="12">
        <v-card elevation="2">
          <v-card-title class="headline">Статистика</v-card-title>
          <v-card-text>
            <div class="actions">
              <v-btn @click="exportToExcel" color="primary" class="custom-btn">
                Експортувати в Excel
              </v-btn>
            </div>
            <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
                class="loading-indicator"
            ></v-progress-circular>
            <v-data-table
                v-else
                :headers="headers"
                :items="formattedEvents"
                item-value="id"
                :items-per-page="50"
                class="elevation-1"
                :header-props="{ style: 'font-weight: bold; background-color: #f5f5f5;' }"
            >
              <template v-slot:[`item.notes`]="{ item }">
                {{ item.notes }}
              </template>
              <template v-slot:[`item.position`]="{ item }">
                {{ item.position }}
              </template>
              <template v-slot:[`item.time`]="{ item }">
                {{ new Date(item.time).toLocaleString() }}
              </template>
              <template v-slot:[`item.reporter`]="{ item }">
                {{ item.reporter.callsign }}
              </template>
              <template v-slot:[`item.mgrs`]="{ item }">
                <span class="mgrs-column-cell">{{ item.mgrs }}</span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import * as XLSX from 'xlsx';
import apiClient from '@/services/axios';
import {formatMGRS} from "@/services/utils";

const events = ref([]);
const loading = ref(true);
const positionsMap = ref({});

const headers = [
  { title: 'Тип події', key: 'type' },
  { title: 'Опис події', key: 'description' },
  { title: 'MGRS', key: 'mgrs' },
  { title: 'Додаткові нотатки', key: 'notes' },
  { title: 'Позиція', key: 'position' },
  { title: 'Час події', key: 'time' },
  { title: 'Позивний репортера', key: 'reporter.callsign' },
];

const formattedEvents = computed(() => {
  return events.value
      .slice()
      .reverse()
      .map(event => {
        const positionName = event.positionId
            ? positionsMap.value[event.positionId] || 'Невідома позиція'
            : event.positionName || 'Невідома позиція';

        const formattedMGRS = event.mgrs === '31NAA6602100000' ? 'N/A' : formatMGRS(event.mgrs);

        return {
          ...event,
          position: positionName,
          reporter: {
            callsign: event.reporter?.callsign || 'Невідомий',
          },
          mgrs: formattedMGRS,
        };
      });
});

async function fetchPositions() {
  try {
    const {data} = await apiClient.getPositions();
    positionsMap.value = data.reduce((map, position) => {
      map[position.id] = position.name;
      return map;
    }, {});
  } catch (error) {
    // no-op
  }
}

async function fetchEvents() {
  try {
    await fetchPositions();
    const {data} = await apiClient.getEvents();
    events.value = data;
  } catch (error) {
    // no-op
  } finally {
    loading.value = false;
  }
}

function exportToExcel() {
  const worksheet = XLSX.utils.json_to_sheet(
      formattedEvents.value.map(({type, description, mgrs, notes, position, time, reporter}) => ({
        Тип: type,
        Опис: description,
        MGRS: mgrs,
        Нотатки: notes,
        Позиція: position,
        Час: new Date(time).toLocaleString(),
        Репортер: reporter.callsign,
      }))
  );

  worksheet['!cols'] = [
    {wch: 10},
    {wch: 30},
    {wch: 15},
    {wch: 15},
    {wch: 20},
    {wch: 30},
    {wch: 20},
  ];

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Події');
  XLSX.writeFile(workbook, 'Звіт_Подій.xlsx');
}

onMounted(fetchEvents);
</script>

<style scoped>
.stats-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
}

.actions {
  text-align: right;
  margin-bottom: 10px;
}

.loading-indicator {
  display: block;
  margin: 0 auto;
}

.v-data-table-header th {
  font-weight: bold !important;
  background-color: #f5f5f5;
  text-align: center;
}

.elevation-1 {
  border: 1px solid #ddd;
  border-radius: 8px;
}

.mgrs-column-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-card {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}

.v-card-title {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.v-card-text {
  padding-top: 10px;
}

.v-btn.custom-btn {
  font-weight: bold;
  background-color: #061A28;
  color: white;
}
</style>
