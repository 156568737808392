<template>
  <v-container class="login-container" fluid>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card elevation="10">
          <v-card-title class="text-h5">Вхід A2</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="login">
              <v-text-field
                  v-model="username"
                  label="Користувач"
                  placeholder="Введіть імʼя користувача"
                  prepend-icon="mdi-account"
                  :error-messages="usernameErrors"
                  :disabled="loading"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="password"
                  label="Пароль"
                  placeholder="Введіть пароль користувача"
                  type="password"
                  prepend-icon="mdi-lock"
                  :error-messages="passwordErrors"
                  :disabled="loading"
                  required
              ></v-text-field>
              <v-btn
                  :disabled="!canLogin || loading"
                  type="submit"
                  color="primary"
                  block
                  class="custom-btn"
              >
                <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="white"
                    size="20"
                ></v-progress-circular>
                <span v-else>Вхід</span>
              </v-btn>
            </v-form>
            <v-alert
                v-if="showAlert"
                type="error"
                dismissible
                v-model="showAlert"
                class="mt-3"
            >
              {{ errorMessage }}
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import User from '../models/User';
import apiClient from "../services/axios";

const username = ref('');
const password = ref('');
const loading = ref(false);
const showAlert = ref(false);
const errorMessage = ref('');
const usernameErrors = ref([]);
const passwordErrors = ref([]);

const router = useRouter();
const route = useRoute();

const canLogin = computed(() => username.value.trim() && password.value);

async function login() {
  clearErrors();
  if (!canLogin.value) {
    if (!username.value.trim()) usernameErrors.value.push('Заповніть поле користувача.');
    if (!password.value) passwordErrors.value.push('Заповніть поле пароля.');
    return;
  }

  loading.value = true;

  try {
    const authResponse = await apiClient.login({ username: username.value, password: password.value });

    console.log('Auth Response:', authResponse);

    if (authResponse.status >= 200 && authResponse.status < 340 && authResponse.data && authResponse.data.access_token) {
      localStorage.setItem('access_token', authResponse.data.access_token);
    } else {
      throw new Error('Login failed, access_token not found.');
    }

    const userResponse = await apiClient.getMe();

    console.log('User Response:', userResponse);

    if (userResponse.status >= 200 && userResponse.status < 340 && userResponse.data) {
      const user = new User(userResponse.data);
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      throw new Error('Failed to retrieve user data.');
    }

    router.push({ path: '/dashboard' });
  } catch (error) {
    console.error('Error during login:', error);
    handleLoginError();
  } finally {
    loading.value = false;
  }
}

function clearErrors() {
  showAlert.value = false;
  usernameErrors.value = [];
  passwordErrors.value = [];
}

function handleLoginError() {
  errorMessage.value = 'Невірне імʼя або пароль!';
  showAlert.value = true;
  username.value = '';
  password.value = '';
}

onMounted(() => {
  if (route.query.sessionExpired) {
    errorMessage.value = 'Сесія закінчилася, будь ласка, увійдіть знову.';
    showAlert.value = true;
  }
});
</script>

<style scoped>
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
</style>
