import Squadron from './Squadron';

class User {
    constructor(data) {
        this.id = data.id;
        this.username = data.username;
        this.hashedPassword = data.hashedPassword;
        this.role = data.role;
        this.squadronId = data.squadronId;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.squadron = data.squadronId ? new Squadron(data.squadron) : null;
        this.callsign = data.callsign;
    }
}

export default User;
