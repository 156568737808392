<template>
  <v-container class="admin-container" fluid>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title class="text-h5">Панель адміністратора</v-card-title>
          <v-card-text>
            <!-- Global Success Alert -->
            <v-alert v-if="successAlert.show" :type="successAlert.type" dismissible @input="successAlert.show = false"
              transition="scale-transition">
              {{ successAlert.message }}
            </v-alert>

            <v-container class="user-info-create-container">
              <div v-if="user">
                <h2>Інформація про користувача</h2>
                <v-form class="user-info-form">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field label="Ім'я користувача" v-model="user.username" readonly />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field label="Позивний" v-model="user.callsign" readonly />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field label="Пункт управління" v-model="userSquadron" readonly />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field label="Підрозділ" v-model="userBattalion" readonly />
                    </v-col>
                  </v-row>
                </v-form>

                <v-container>
                  <v-row class="button-group">
                    <v-col cols="12" sm="6" v-if="user && (user.role === 'admin' || user.role === 'battalion_admin')">
                      <v-btn @click="openCreateUserDialog" color="primary" block class="custom-btn">
                        Новий користувач
                      </v-btn>
                    </v-col>

                    <v-col cols="12" sm="6"
                      v-if="user && (user.role === 'admin' || user.role === 'battalion_admin' || user.role === 'squadron_admin')">
                      <v-btn @click="openCreatePositionDialog" color="primary" block class="custom-btn">
                        Нова позиція, група, позивний
                      </v-btn>
                    </v-col>

                    <v-col cols="12" sm="6" v-if="user && (user.role === 'admin' || user.role === 'battalion_admin')">
                      <v-btn @click="openCreateSquadronDialog" color="primary" block class="custom-btn">
                        Новий Пункт управління
                      </v-btn>
                    </v-col>

                    <v-col cols="12" sm="6" v-if="user && user.role === 'admin'">
                      <v-btn @click="openCreateBattalionDialog" color="primary" block class="custom-btn">
                        Новий Підрозділ
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>

                <v-divider class="my-4" />

                <!-- Updated Table Tabulation -->
                <v-container class="button-group" fluid>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-btn @click="setView('battalions')" :class="getButtonClass('battalions')" block>
                        Підрозділи
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-btn @click="setView('squadrons')" :class="getButtonClass('squadrons')" block>
                        Пункти управління
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-btn @click="setView('positions')" :class="getButtonClass('positions')" block>
                        Позиції, групи, позивні
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-btn @click="setView('users')" :class="getButtonClass('users')" block>
                        Користувачі
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>

              </div>
            </v-container>

            <!-- Filtering Selects -->
            <v-row v-if="view === 'squadrons' || view === 'positions'" class="my-4">
              <v-col cols="12" sm="6">
                <v-select v-if="view === 'squadrons' || view === 'positions'" label="Фільтрувати по Підрозділу"
                  :items="battalionsFilterItems" v-model="selectedBattalion" item-title="name" item-value="id" clearable
                  hide-details />
              </v-col>
              <v-col cols="12" sm="6" v-if="view === 'positions'">
                <v-select label="Фільтрувати по Пункту управління" :items="positionsSquadronsFilterItems"
                  v-model="selectedSquadron" item-title="display" item-value="id" clearable hide-details />
              </v-col>
            </v-row>

            <!-- Conditional Rendering of Tables -->
            <div class="table-container">
              <div v-if="view === 'battalions'">
                <h2 class="my-4">Список Підрозділів</h2>
                <v-data-table :headers="battalionHeaders" :items="battalions" class="mb-4"
                              :header-props="{ style: 'font-weight: bold; background-color: #f5f5f5;' }">
                  <template v-slot:[`item.signalChats`]="{ item }">
                    <div v-if="item.signalChats?.length" class="chat-names-container">
                      <v-chip v-for="(chat, index) in item.signalChats" :key="`signal-${index}`" class="chat-name-chip"
                              @click="openChatConfigDialog('signal', item, chat)">
                        {{ chat }}
                      </v-chip>
                    </div>
                  </template>

                  <template v-slot:[`item.whatsappChats`]="{ item }">
                    <div v-if="item.whatsappChats?.length" class="chat-names-container">
                      <v-chip v-for="(chat, index) in item.whatsappChats" :key="`whatsapp-${index}`"
                              class="chat-name-chip" @click="openChatConfigDialog('whatsapp', item, chat)">
                        {{ chat }}
                      </v-chip>
                    </div>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <div class="actions-container">
                      <v-btn class="custom-yellow-btn" v-if="(user.role !== 'squadron_admin' && view !== 'positions')"
                             @click="openEditBattalionDialog(item)">
                        Редагувати
                      </v-btn>
                      <v-btn class="custom-red-btn" v-if="(user.role !== 'squadron_admin' && view !== 'positions')"
                             @click="removeBattalion(item)">
                        Видалити
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
              </div>

              <div v-else-if="view === 'squadrons'">
                <h2 class="my-4">Список Пунктів управління</h2>
                <v-data-table :headers="squadronHeaders" :items="filteredSquadrons" class="mb-4" item-value="id"
                  :header-props="{ style: 'font-weight: bold; background-color: #f5f5f5;' }">
                  <template v-slot:[`item.name`]="{ item }">
                    {{ item.name }}
                    <span class="battalion-name"> ({{ item.battalionName ?? '' }}) </span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <div class="actions-container">
                      <v-btn class="custom-yellow-btn" v-if="(user.role !== 'squadron_admin' && view !== 'positions')"
                        @click="openEditSquadronDialog(item)">
                        Редагувати
                      </v-btn>
                      <v-btn class="custom-red-btn" v-if="(user.role !== 'squadron_admin' && view !== 'positions')"
                        @click="removeSquadron(item)">
                        Видалити
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
              </div>

              <div v-else-if="view === 'positions'">
                <h2 class="my-4">Список позицій, груп, позивних</h2>

                <!-- Позиції Section -->
                <h3>Позиції</h3>
                <v-data-table :headers="positionHeaders" :items="filteredPositions.filter(item => !item.isGroup)"
                  class="mb-4" item-value="id"
                  :header-props="{ style: 'font-weight: bold; background-color: #f5f5f5;' }">
                  <template v-slot:[`item.name`]="{ item }">
                    {{ item.name }}
                    <span v-if="item.description" class="description">
                      ({{ item.description }})
                    </span>
                  </template>
                  <template v-slot:[`item.squadron`]="{ item }">
                    <span v-if="item.squadronName">
                      {{ item.squadronName }}
                      <span v-if="item.battalionName" class="battalion-name">
                        ({{ item.battalionName }})
                      </span>
                    </span>
                    <span v-else>N/A</span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <div class="actions-container">
                      <v-btn class="custom-yellow-btn"
                        v-if="(user.role === 'admin' || user.role === 'battalion_admin' || user.role === 'squadron_admin') && view === 'positions'"
                        @click="openEditPositionDialog(item)">
                        Редагувати
                      </v-btn>
                      <v-btn class="custom-red-btn"
                        v-if="(user.role === 'admin' || user.role === 'battalion_admin' || user.role === 'squadron_admin') && view === 'positions'"
                        @click="removePosition(item)">
                        Видалити
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>

                <!-- Групи Section -->
                <h3>Групи та позивні</h3>
                <v-data-table :headers="positionHeaders" :items="filteredPositions.filter(item => item.isGroup)"
                  class="mb-4" item-value="id"
                  :header-props="{ style: 'font-weight: bold; background-color: #f5f5f5;' }">
                  <template v-slot:[`item.name`]="{ item }">
                    {{ item.name }}
                    <span v-if="item.description" class="description">
                      ({{ item.description }})
                    </span>
                  </template>
                  <template v-slot:[`item.squadron`]="{ item }">
                    <span v-if="item.squadronName">
                      {{ item.squadronName }}
                      <span v-if="item.battalionName" class="battalion-name">
                        ({{ item.battalionName }})
                      </span>
                    </span>
                    <span v-else>N/A</span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <div class="actions-container">
                      <v-btn class="custom-yellow-btn"
                        v-if="(user.role === 'admin' || user.role === 'battalion_admin' || user.role === 'squadron_admin') && view === 'positions'"
                        @click="openEditPositionDialog(item)">
                        Редагувати
                      </v-btn>
                      <v-btn class="custom-red-btn"
                        v-if="(user.role === 'admin' || user.role === 'battalion_admin' || user.role === 'squadron_admin') && view === 'positions'"
                        @click="removePosition(item)">
                        Видалити
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
              </div>

              <div v-else-if="view === 'users'">
                <h2 class="my-4">Список користувачів</h2>
                <v-data-table :headers="userHeaders" :items="filteredUsers" class="mb-4" item-value="id"
                  :header-props="{ style: 'font-weight: bold; background-color: #f5f5f5;' }">
                  <!-- Properly display squadron name instead of full object -->
                  <template v-slot:[`item.squadron`]="{ item }">
                    {{ getSquadronWithBattalion(item.squadron) || 'N/A' }}
                  </template>

                  <!-- Display the role with the roleTranslations map -->
                  <template v-slot:[`item.role`]="{ item }">
                    {{ roleTranslations[item.role] || 'Unknown' }}
                  </template>

                  <!-- User actions: Edit and Delete -->
                  <template v-slot:[`item.actions`]="{ item }">
                    <div class="actions-container">
                      <v-btn class="custom-yellow-btn" v-if="(user.role !== 'squadron_admin' && view !== 'positions')"
                        @click="openEditUserDialog(item)">
                        Редагувати
                      </v-btn>
                      <v-btn class="custom-red-btn" v-if="(user.role !== 'squadron_admin' && view !== 'positions')"
                        @click="removeUser(item)">
                        Видалити
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
              </div>

              <v-alert v-else type="info" prominent> Завантаження інформації про користувача... </v-alert>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialogs for creating and editing user, squadron, battalion, and position creation -->
    <v-dialog v-model="userDialog" max-width="500px">
      <v-card>
        <v-card-title>{{ isEditingUser ? 'Оновити користувача' : 'Створити користувача' }}</v-card-title>
        <v-card-text>
          <!-- Alert inside User Dialog -->
          <v-alert v-if="userAlert.show" :type="userAlert.type" dismissible @input="userAlert.show = false"
            transition="scale-transition">
            {{ userAlert.message }}
          </v-alert>

          <v-form ref="userFormRef">
            <v-text-field label="Ім'я користувача" v-model="userForm.username"
              :rules="[v => !!v || 'Імя користувача обовязкове']" required />
            <v-text-field label="Пароль" type="password" v-model="userForm.password"
              placeholder="Залиште порожнім для незмінного пароля" />
            <!-- Updated role dropdown using roleTranslations -->
            <v-select label="Роль" v-model="userForm.role" :items="filteredRoleItems" item-title="displayName"
              item-value="value" :rules="[v => !!v || 'Роль обовязкова']" required />
            <v-text-field label="Позивний" v-model="userForm.callsign" :rules="[v => !!v || 'Позивний обовязковий']"
              required />
            <v-select label="Підрозділ" v-model="userForm.squadronId" :items="allSquadronItems" item-title="display"
              item-value="id" :rules="[v => !!v || 'Підрозділ']" required />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="custom-red-btn" @click="userDialog = false"> Відмінити </v-btn>
          <v-btn class="custom-green-btn" @click="isEditingUser ? updateUser() : createUser()">
            {{ isEditingUser ? 'Оновити' : 'Створити' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog for creating and editing squadron -->
    <v-dialog v-model="squadronDialog" max-width="500px">
      <v-card>
        <v-card-title>{{ isEditMode.squadron ? 'Редагувати Пункт управління' : 'Створити Пункт управління'
          }}</v-card-title>
        <v-card-text>
          <!-- Alert inside Squadron Dialog -->
          <v-alert v-if="squadronAlert.show" :type="squadronAlert.type" dismissible @input="squadronAlert.show = false"
            transition="scale-transition">
            {{ squadronAlert.message }}
          </v-alert>

          <v-form ref="squadronFormRef">
            <v-text-field label="Назва" v-model="squadronForm.name" :rules="[v => !!v || 'Назва обовязкова']"
              required />
            <v-select label="Підрозділ" v-model="squadronForm.battalionId" :items="activeBattalionItems"
              item-title="name" item-value="id" :rules="[v => !!v || 'Підрозділ обовязковий']" required />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="custom-red-btn" @click="squadronDialog = false"> Відмінити </v-btn>
          <v-btn class="custom-green-btn" @click="isEditMode.squadron ? updateSquadron() : createSquadron()"> Зберегти
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="battalionDialog" max-width="500px">
      <v-card>
        <v-card-title>{{ isEditMode.battalion ? 'Редагувати Підрозділ' : 'Створити Підрозділ' }}</v-card-title>
        <v-card-text>
          <!-- Alert inside Battalion Dialog -->
          <v-alert v-if="battalionAlert.show" :type="battalionAlert.type" dismissible
            @input="battalionAlert.show = false" transition="scale-transition">
            {{ battalionAlert.message }}
          </v-alert>

          <v-form ref="battalionFormRef">
            <v-text-field label="Назва" v-model="battalionForm.name" :rules="[v => !!v || 'Назва обовязкова']"
              required />

            <v-text-field label="Додати Signal чат" v-model="newSignalChat"
              @keypress.enter="handleKeyPress($event, 'signal')" @blur="addSignalChat" />

            <div v-if="battalionForm.signalChats.length">
              <v-chip v-for="(chat, index) in battalionForm.signalChats" :key="`${chat}-${index}`" closable
                @click:close="removeChatFromArray('signalChats', chat)">
                {{ chat }}
              </v-chip>
            </div>

            <v-text-field label="Додати WhatsApp чат" v-model="newWhatsappChat"
              @keypress.enter="handleKeyPress($event, 'whatsapp')" @blur="addWhatsappChat" />

            <div v-if="battalionForm.whatsappChats.length">
              <v-chip v-for="(chat, index) in battalionForm.whatsappChats" :key="`${chat}-${index}`" closable
                @click:close="removeChatFromArray('whatsappChats', chat)">
                {{ chat }}
              </v-chip>
            </div>
          </v-form>

        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="custom-red-btn" @click="battalionDialog = false"> Відмінити </v-btn>
          <v-btn class="custom-green-btn" @click="isEditMode.battalion ? updateBattalion() : createBattalion()">
            Зберегти
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog for creating and editing position -->
    <v-dialog v-model="positionDialog" max-width="500px">
      <v-card>
        <v-card-title>{{ isEditMode.position ? 'Редагувати позицію, групу, позивний' : 'Створити позицію, групу, позивний'
          }}</v-card-title>
        <v-card-text>
          <!-- Alert inside Position Dialog -->
          <v-alert v-if="positionAlert.show" :type="positionAlert.type" dismissible @input="positionAlert.show = false"
            transition="scale-transition">
            {{ positionAlert.message }}
          </v-alert>

          <v-form ref="positionFormRef">
            <v-text-field label="Назва" v-model="positionForm.name" :rules="[v => !!v || 'Назва обовязкова']"
              required />
            <v-text-field label="Опис" v-model="positionForm.description" />
            <v-select label="Пункт управління" v-model="positionForm.squadronId" :items="activeNonServiceSquadronItems"
              item-title="display" item-value="id" :rules="[v => !!v || 'Пункт управління обовязковий']" required />
            <v-checkbox 
            label="Група чи позивний" 
            v-model="positionForm.isGroup"
            :true-value="true"
            :false-value="false"
            ></v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="custom-red-btn" @click="positionDialog = false"> Відмінити </v-btn>
          <v-btn class="custom-green-btn" @click="isEditMode.position ? updatePosition() : createPosition()"> Зберегти
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Confirm Delete Dialog -->
    <v-dialog v-model="confirmDeleteDialog.show" max-width="500px">
      <v-card>
        <v-card-title>Підтвердити видалення</v-card-title>
        <v-card-text> Ви впевнені, що хочете видалити цей елемент? Ця дія не може бути скасована. </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="custom-default-btn" @click="confirmDeleteDialog.show = false"> Скасувати </v-btn>
          <v-btn class="custom-red-btn" @click="executeDeleteAction"> Видалити </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ChatConfigDialog v-if="selectedChat && isChatConfigDialogVisible" :dialogVisible="isChatConfigDialogVisible"
      :chatName="selectedChat.name" :config="selectedChat.config" @save="handleSaveChatConfig"
      @close="handleCloseChatConfig" />
  </v-container>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue';
import apiClient from '@/services/axios';
import ChatConfigDialog from './ChatConfigDialog.vue';
import { getUserFromLocalStorage } from '@/services/utils';

// State variables for the dialog
const isChatConfigDialogVisible = ref(false);
const selectedChat = ref(null);

function openChatConfigDialog(chatType, battalion, chatName) {
  const chatData = chatType === 'signal'
    ? battalion.signalChatsData.find(chat => chat.name === chatName)
    : battalion.whatsappChatsData.find(chat => chat.name === chatName);

  if (chatData) {
    // Include the chatType in the selectedChat object
    selectedChat.value = { ...chatData, battalion, type: chatType };
    isChatConfigDialogVisible.value = true;
    console.log('Dialog opened with battalion:', battalion);
    console.log('Chat Type:', chatType);  // Ensure chatType is logged
  }
}

function handleCloseChatConfig() {
  isChatConfigDialogVisible.value = false;
}

async function handleSaveChatConfig(updatedChatConfig) {
  console.log("new cfg", updatedChatConfig);
  const { name, battalion, type } = selectedChat.value;
  const chatsDataKey = type === 'signal' ? 'signalChatsData' : 'whatsappChatsData';

  const chatIndex = battalion[chatsDataKey].findIndex(chat => chat.name === name);
  if (chatIndex !== -1) {
    battalion[chatsDataKey][chatIndex].config = updatedChatConfig;

    const requestBody = {
      [chatsDataKey]: battalion[chatsDataKey],
    };

    try {
      await apiClient.editBatallion(battalion.id, requestBody);
      fetchData();
      showAlert(`Чат ${type === 'signal' ? 'Signal' : 'WhatsApp'} успішно оновлено`);
    } catch (error) {
      showAlert('Помилка оновлення чату', 'error');
    }
  } else {
    console.error('Chat not found in battalion chats data.');
  }

  isChatConfigDialogVisible.value = false;
}

// State variables
const user = ref(null);
const squadrons = ref([]);
const battalions = ref([]);
const positions = ref([]);
const users = ref([]);
const view = ref('battalions');
const isEditingUser = ref(false);

// Filter state variables
const selectedBattalion = ref(null);
const selectedSquadron = ref(null);

var userSquadron = '';
var userBattalion = '';

// Global Success Alert State
const successAlert = reactive({
  show: false,
  message: '',
});

// Alert state for each dialog
const userAlert = reactive({
  show: false,
  type: 'error',
  message: '',
});

const squadronAlert = reactive({
  show: false,
  type: 'error',
  message: '',
});

const battalionAlert = reactive({
  show: false,
  type: 'error',
  message: '',
});

const positionAlert = reactive({
  show: false,
  type: 'error',
  message: '',
});

// Dialog visibility states
const userDialog = ref(false);
const squadronDialog = ref(false);
const battalionDialog = ref(false);
const positionDialog = ref(false);

// Confirm delete dialog state
const confirmDeleteDialog = reactive({
  show: false,
  item: null,
  action: null,
});

// Edit mode flags
const isEditMode = reactive({
  user: false,
  squadron: false,
  battalion: false,
  position: false,
});

// Form states
const userForm = reactive({
  id: null,
  username: '',
  password: '',
  role: '',
  callsign: '',
  squadronId: '',
});

const squadronForm = reactive({
  id: null,
  name: '',
  battalionId: null,
});

const battalionForm = reactive({
  id: null,
  name: '',
  signalChats: [], // Updated to an array
  whatsappChats: [], // Updated to an array
});

const positionForm = reactive({
  id: null,
  name: '',
  description: '',
  squadronId: '',
  isGroup: false,
});

// State for new chat inputs
const newSignalChat = ref('');
const newWhatsappChat = ref('');

// Methods to handle chat input additions
function addSignalChat() {
  if (newSignalChat.value.trim() !== '' && !battalionForm.signalChats.includes(newSignalChat.value.trim())) {
    battalionForm.signalChats.push(newSignalChat.value.trim());
    newSignalChat.value = ''; // Clear the input after adding
  }
}

function addWhatsappChat() {
  if (newWhatsappChat.value.trim() !== '' && !battalionForm.whatsappChats.includes(newWhatsappChat.value.trim())) {
    battalionForm.whatsappChats.push(newWhatsappChat.value.trim());
    newWhatsappChat.value = ''; // Clear the input after adding
  }
}

// Available roles
const roleTranslations = {
  user: 'Користувач',
  stat: 'Аналітик',
  admin: 'Суперадмін',
  battalion_admin: 'Адмін батальону',
  squadron_admin: 'Адмін роти',
};

const roleItems = Object.keys(roleTranslations).map((roleKey) => ({
  value: roleKey, // The actual role value
  displayName: roleTranslations[roleKey], // The translated display name
}));

const filteredRoleItems = computed(() => {
  console.log('user', user.value);
  // If the logged-in user is admin, show all roles, including 'admin'
  if (user.value.role === 'admin') {
    return roleItems;
  }

  // If the logged-in user is battalion_admin or squadron_admin, show only battalion_admin and squadron_admin roles
  if (user.value.role === 'battalion_admin' || user.value.role === 'squadron_admin') {
    return roleItems.filter((role) => role.value !== 'admin');
  }

  // Default to showing no roles (in case of unexpected role)
  return [];
});


// Table headers
const squadronHeaders = [
  { title: 'Ім’я', key: 'name' },
  { title: 'Дії', key: 'actions', sortable: false }
];
const battalionHeaders = [
  { title: 'Ім’я', key: 'name' },
  { title: 'Signal чат', key: 'signalChats' },
  { title: 'WhatsApp чат', key: 'whatsappChats' },
  { title: 'Дії', key: 'actions', sortable: false }
];
const positionHeaders = [
  { title: 'Назва (Опис)', key: 'name' },
  { title: 'Підрозділ', key: 'squadron' },
  { title: 'Дії', key: 'actions', sortable: false },
];
const userHeaders = [
  { title: 'Ім’я користувача', key: 'username' },
  { title: 'Роль', key: 'role' },
  { title: 'Позивний', key: 'callsign' },
  { title: 'Підрозділ', key: 'squadron' },
  { title: 'Дії', key: 'actions', sortable: false },
];

// Computed properties for dropdowns
const battalionsFilterItems = computed(() =>
  battalions.value.map((battalion) => ({
    name: battalion.name,
    id: battalion.id,
  }))
);

const positionsSquadronsFilterItems = computed(() =>
  squadrons.value
    .filter((squadron) =>
      selectedBattalion.value ? squadron.battalionId === selectedBattalion.value : true
    )
    .sort((a, b) => a.battalionName.localeCompare(b.battalionName))
    .map((squadron) => ({
      id: squadron.id,
      display: `${squadron.name} (${squadron.battalionName ?? ''})`,
    }))
);

const allSquadronItems = computed(() =>
  squadrons.value.map((squadron) => ({
    id: squadron.id,
    name: squadron.name ?? '',
    display: `${squadron.name} (${squadron.battalionName ?? ''})`,
  }))
);

const activeNonServiceSquadronItems = computed(() =>
  squadrons.value
    .filter((squadron) => !squadron.isService)
    .map((squadron) => ({
      id: squadron.id,
      name: squadron.name ?? '',
      display: `${squadron.name} (${squadron.battalionName ?? ''})`,
    }))
);

const activeBattalionItems = computed(() =>
  battalions.value
    .filter((battalion) => !battalion.isService)
    .map((battalion) => ({
      name: battalion.name ?? '',
      id: battalion.id,
    }))
);

const filteredSquadrons = computed(() => {
  return squadrons.value
    .filter((squadron) =>
      selectedBattalion.value ? squadron.battalionId === selectedBattalion.value : true
    )
    .sort((a, b) => a.battalionName.localeCompare(b.battalionName));
});

const filteredPositions = computed(() => {
  return positions.value
    .filter((position) =>
      selectedBattalion.value
        ? position.battalionName === battalions.value.find((battalion) => battalion.id === selectedBattalion.value).name
        : true
    )
    .filter((position) =>
      selectedSquadron.value ? position.squadronId === selectedSquadron.value : true
    )
    .sort((a, b) => a.name.localeCompare(b.name));
});

const filteredUsers = computed(() => {
  return users.value.map((user) => ({
    ...user,
    squadron: squadrons.value.find((sq) => sq.id === user.squadronId) || {},
  }));
});

// Fetch data from API
async function fetchData() {
  try {
    const battalionResponse = await apiClient.getBatallions();
    let battalionsData = Array.isArray(battalionResponse.data)
      ? battalionResponse.data
      : [battalionResponse.data]; // Ensure it's an array

    battalions.value = battalionsData.map((battalion) => {
      const signalChats = Array.isArray(battalion.signalChatsData)
        ? battalion.signalChatsData
          .filter(chat => chat && chat.name) // Filter out any null or undefined chats
          .map((chat) => chat.name || '')
        : [];
      const whatsappChats = Array.isArray(battalion.whatsappChatsData)
        ? battalion.whatsappChatsData
          .filter(chat => chat && chat.name) // Filter out any null or undefined chats
          .map((chat) => chat.name || '')
        : [];

      return {
        ...battalion,
        signalChats,
        whatsappChats,
        signalChatsData: battalion.signalChatsData || [], // Ensure non-null value
        whatsappChatsData: battalion.whatsappChatsData || [], // Ensure non-null value
      };
    });

    // Create a map of battalion IDs to names for quick lookup
    const battalionMap = battalions.value.reduce((map, battalion) => {
      map[battalion.id] = battalion.name;
      return map;
    }, {});

    // Fetch squadrons
    const squadronResponse = await apiClient.getSquadrons();
    let squadronsData = Array.isArray(squadronResponse.data)
      ? squadronResponse.data
      : [squadronResponse.data]; // Ensure it's an array

    squadrons.value = squadronsData.map((squadron) => ({
      ...squadron,
      battalionName: battalionMap[squadron.battalionId] || 'N/A', // Handle missing battalion names
    }));

    // Fetch positions and add squadron and battalion info
    const positionResponse = await apiClient.getPositions();
    let positionsData = Array.isArray(positionResponse.data)
      ? positionResponse.data
      : [positionResponse.data]; // Ensure it's an array

    positions.value = positionsData.map((position) => {
      const squadron = squadrons.value.find((sq) => sq.id === position.squadronId);
      return {
        ...position,
        squadronName: squadron?.name || 'N/A', // Handle missing squadron names
        battalionName: squadron?.battalionName || 'N/A', // Handle missing battalion names
        isGroup: position.isGroup || false,
      };
    });

    // Fetch users and add squadron info
    const usersResponse = await apiClient.getAllUsers();
    let usersData = Array.isArray(usersResponse.data)
      ? usersResponse.data
      : [usersResponse.data]; // Ensure it's an array

    users.value = usersData;

  } catch (error) {
    console.log('error', error);
    showAlert('Дані не отримано', 'error');
  }
}

// Helper function to get the squadron name with battalion
function getSquadronWithBattalion(squadron) {
  if (!squadron || !squadron.name) return 'N/A';
  const battalionName = squadron.battalionName ? ` (${squadron.battalionName})` : '';
  return `${squadron.name}${battalionName}`;
}

function removeChatFromArray(arrayName, chat) {
  battalionForm[arrayName] = battalionForm[arrayName].filter((c) => c !== chat);
}


// Dialog open functions
function openCreateUserDialog() {
  isEditingUser.value = false;
  userDialog.value = true;
  resetForm(userForm);
}

function openCreateSquadronDialog() {
  isEditMode.squadron = false;
  squadronDialog.value = true;
  resetForm(squadronForm);
}

function openCreateBattalionDialog() {
  isEditMode.battalion = false;
  battalionDialog.value = true;
  resetForm(battalionForm);
}

function openCreatePositionDialog() {
  isEditMode.position = false;
  positionDialog.value = true;
  resetForm(positionForm);
}

// Edit Dialog open functions
function openEditUserDialog(item) {
  isEditingUser.value = true;
  userDialog.value = true;

  // Populate the form with existing user data
  userForm.id = item.id;
  userForm.username = item.username;
  userForm.role = item.role;
  userForm.callsign = item.callsign;
  userForm.squadronId = item.squadronId;
  userForm.password = ''; // Leave password empty for update
}

function openEditSquadronDialog(item) {
  isEditMode.squadron = true;
  squadronForm.id = item.id;
  squadronForm.name = item.name;
  squadronForm.battalionId = item.battalionId;
  squadronDialog.value = true;
}

function openEditBattalionDialog(item) {
  isEditMode.battalion = true;

  // Ensure item has an id and name before proceeding
  if (!item || !item.id || !item.name) {
    console.error('Invalid battalion item:', item);
    return;
  }

  battalionForm.id = item.id;
  battalionForm.name = item.name;

  // Handle potential null or undefined signalChatsData and whatsappChatsData
  battalionForm.signalChats = Array.isArray(item.signalChatsData)
    ? item.signalChatsData.filter(chat => chat && chat.name).map(chat => chat.name)
    : [];

  battalionForm.whatsappChats = Array.isArray(item.whatsappChatsData)
    ? item.whatsappChatsData.filter(chat => chat && chat.name).map(chat => chat.name)
    : [];

  // Ensure signalChatsData and whatsappChatsData are not null or undefined
  battalionForm.signalChatsData = item.signalChatsData || [];
  battalionForm.whatsappChatsData = item.whatsappChatsData || [];

  battalionDialog.value = true;
}


function openEditPositionDialog(item) {
  isEditMode.position = true;
  positionForm.id = item.id;
  positionForm.name = item.name;
  positionForm.description = item.description;
  positionForm.squadronId = item.squadronId;
  positionForm.isGroup = item.isGroup;
  positionDialog.value = true;
}

// Reset form function
function resetForm(form) {
  for (const key in form) {
    if (Object.prototype.hasOwnProperty.call(form, key)) {
      form[key] = Array.isArray(form[key]) ? [] : '';
    }
  }
}

// Create functions
async function createUser() {
  const { username, password, role, callsign, squadronId } = userForm;

  // Validation check
  if (!username || !password || !role || !callsign || !squadronId) {
    showDialogAlert(userAlert, 'Усі обовязкові поля мають бути заповнені');
    return;
  }

  if (role === 'admin' && user.value.role !== 'admin') {
    showDialogAlert(userAlert, 'Тільки адміністратори можуть створювати інших адміністраторів');
    return;
  }

  const requestBody = {
    username,
    password,
    role,
    callsign,
    squadronId,
  };

  try {
    await apiClient.createUser(requestBody);
    userDialog.value = false;
    showAlert('Користувач успішно створено');
    fetchData(); // Re-fetch data to update lists
  } catch (error) {
    showDialogAlert(userAlert, 'Помилка створення користувача');
  }
}

async function createSquadron() {
  const { name, battalionId } = squadronForm;

  // Validation check
  if (!name || !battalionId) {
    showDialogAlert(squadronAlert, 'Назва та Підрозділ обовязкові');
    return;
  }

  const requestBody = {
    name,
    battalionId,
  };

  try {
    await apiClient.createSquadron(requestBody);
    squadronDialog.value = false;
    showAlert('Пункт управління успішно створено');
    fetchData();
  } catch (error) {
    showDialogAlert(squadronAlert, 'Помилка створення Пункту управління');
  }
}

async function createBattalion() {
  const { name, signalChats, whatsappChats } = battalionForm;

  // Validation check
  if (!name) {
    showDialogAlert(battalionAlert, 'Назва обовязкова');
    return;
  }

  const wrappedSignalChats = signalChats.map(chat => {
    const existingChat = (battalionForm.signalChatsData || []).find(c => c.name === chat);
    return {
      name: chat,
      config: existingChat ? existingChat.config : null, // Keep existing config if it exists
    };
  });

  const wrappedWhatsappChats = signalChats.map(chat => {
    const existingChat = (battalionForm.whatsappChatsData || []).find(c => c?.name === chat);
    return {
      name: chat,
      config: existingChat ? existingChat.config : null, // Keep existing config if it exists
    };
  });


  const requestBody = {
    name,
    signalChatsData: wrappedSignalChats,
    whatsappChatsData: wrappedWhatsappChats,
  };

  try {
    await apiClient.createBatallion(requestBody);
    battalionDialog.value = false;
    showAlert('Підрозділ успішно створено');
    fetchData();
  } catch (error) {
    showDialogAlert(battalionAlert, 'Помилка створення Підрозділу');
  }
}

async function createPosition() {
  const { name, description, squadronId } = positionForm;

  // Validation check
  if (!name || !squadronId) {
    showDialogAlert(positionAlert, 'Назва та Пункт управління обовязкові');
    return;
  }

  const requestBody = {
    name,
    description,
    squadronId,
    isGroup: positionForm.isGroup,
  };

  try {
    await apiClient.createPosition(requestBody);
    positionDialog.value = false;
    showAlert('Позиція, групу, позивний успішно створено');
    await fetchData();
  } catch (error) {
    showDialogAlert(positionAlert, 'Помилка створення позиції, групи, позивного');
  }
}

// Update functions
async function updateUser() {
  const { id, username, role, callsign, squadronId, password } = userForm;

  // Validation check
  if (!id || !username || !role || !callsign || !squadronId) {
    showDialogAlert(userAlert, 'Усі обовязкові поля мають бути заповнені');
    return;
  }

  // Prepare request body
  const requestBody = {
    username,
    role,
    callsign,
    squadronId,
  };

  // Include password only if it's not empty
  if (password) {
    requestBody.password = password;
  }

  try {
    await apiClient.editUser(id, requestBody);
    userDialog.value = false;
    showAlert('Користувач успішно оновлено');
    fetchData(); // Re-fetch data to update lists
  } catch (error) {
    showDialogAlert(userAlert, 'Помилка оновлення користувача');
  }
}

async function updateSquadron() {
  const { id, name, battalionId } = squadronForm;

  // Validation check
  if (!id || !name || !battalionId) {
    showDialogAlert(squadronAlert, 'Назва та Підрозділ обовязкові');
    return;
  }

  const requestBody = {
    name,
    battalionId,
  };

  try {
    await apiClient.editSquadron(id, requestBody);
    squadronDialog.value = false;
    showAlert('Пункт управління успішно оновлено');
    fetchData();
  } catch (error) {
    showDialogAlert(squadronAlert, 'Помилка оновлення Пункту управління');
  }
}

async function updateBattalion() {
  const { id, name, signalChats, whatsappChats } = battalionForm;

  console.log('form', battalionForm);
  if (!id || !name) {
    showDialogAlert(battalionAlert, 'Назва обовязкова');
    return;
  }

  // Retain existing configs for Signal chats
  const wrappedSignalChats = signalChats.map(chat => {
    const existingChat = (battalionForm.signalChatsData || []).find(c => c?.name === chat);  // Ensure signalChatsData is an array and check if 'c' exists
    return {
      name: chat,
      config: existingChat ? existingChat.config : null, // Keep existing config if it exists
    };
  });

  // Retain existing configs for WhatsApp chats
  const wrappedWhatsappChats = whatsappChats.map(chat => {
    const existingChat = (battalionForm.whatsappChatsData || []).find(c => c?.name === chat);  // Ensure whatsappChatsData is an array and check if 'c' exists
    return {
      name: chat,
      config: existingChat ? existingChat.config : null, // Keep existing config if it exists
    };
  });

  const requestBody = {
    name,
    signalChatsData: wrappedSignalChats,
    whatsappChatsData: wrappedWhatsappChats,
  };

  try {
    console.log("Update battalion", requestBody);
    await apiClient.editBatallion(id, requestBody);
    battalionDialog.value = false;
    showAlert('Підрозділ успішно оновлено');
    fetchData();
  } catch (error) {
    showDialogAlert(battalionAlert, 'Помилка оновлення Підрозділу');
  }
}


async function updatePosition() {
  const { id, name, description, squadronId, isGroup } = positionForm;

  // Validation check
  if (!id || !name || !squadronId) {
    showDialogAlert(positionAlert, 'Назва та Пункту управління обовязкові');
    return;
  }

  const requestBody = {
    name,
    description,
    squadronId,
    isGroup
  };

  try {
    await apiClient.editPosition(id, requestBody);
    positionDialog.value = false;
    showAlert('Позицію, групу, позивний успішно оновлено');
    fetchData();
  } catch (error) {
    showDialogAlert(positionAlert, 'Помилка оновлення позиції, групи, позивного');
  }
}

// Functions to handle deletion
function removeUser(item) {
  confirmDeleteDialog.show = true;
  confirmDeleteDialog.item = item;
  confirmDeleteDialog.action = async () => {
    try {
      await apiClient.removeUser(item.id);
      confirmDeleteDialog.show = false;
      showAlert('Користувач успішно видалений');
      fetchData();
    } catch (error) {
      showDialogAlert(userAlert, 'Помилка видалення користувача');
    }
  };
}

function removeSquadron(item) {
  if (item.isService) {
    showAlert('Цей Пункт управління не може бути видалений, оскільки він є сервісним.', 'error');
    return;
  }

  confirmDeleteDialog.show = true;
  confirmDeleteDialog.item = item;
  confirmDeleteDialog.action = async () => {
    try {
      await apiClient.removeSquadron(item.id);
      confirmDeleteDialog.show = false;
      showAlert('Пункт управління успішно видалений');
      fetchData();
    } catch (error) {
      showDialogAlert(squadronAlert, 'Помилка видалення Пункту управління');
    }
  };
}

function removeBattalion(item) {
  if (item.isService) {
    showAlert('Цей Підрозділ не може бути видалений, оскільки він є сервісним.', 'error');
    return;
  }

  confirmDeleteDialog.show = true;
  confirmDeleteDialog.item = item;
  confirmDeleteDialog.action = async () => {
    try {
      await apiClient.removeBatallion(item.id);
      confirmDeleteDialog.show = false;
      showAlert('Підрозділ успішно видалений');
      fetchData();
    } catch (error) {
      showDialogAlert(battalionAlert, 'Помилка видалення Підрозділу');
    }
  };
}

function removePosition(item) {
  confirmDeleteDialog.show = true;
  confirmDeleteDialog.item = item;
  confirmDeleteDialog.action = async () => {
    try {
      await apiClient.removePosition(item.id);
      confirmDeleteDialog.show = false;
      showAlert('Позиція успішно видалена');
      fetchData();
    } catch (error) {
      showDialogAlert(positionAlert, 'Помилка видалення позиції, групи, позивного');
    }
  };
}

// Show alert functions for dialogs
function showDialogAlert(alertState, message) {
  alertState.message = message;
  alertState.show = true;

  // Automatically hide alert after 3 seconds
  setTimeout(() => {
    alertState.show = false;
  }, 3000);
}

// Show global success alert function
function showAlert(message, type = 'success') {
  successAlert.message = message;
  successAlert.type = type;
  successAlert.show = true;

  // Automatically hide alert after 3 seconds
  setTimeout(() => {
    successAlert.show = false;
  }, 3000);
}

function setView(selectedView) {
  view.value = selectedView;
}

function getButtonClass(buttonView) {
  return view.value === buttonView ? 'custom-btn active-tab' : 'custom-btn';
}

onMounted(() => {
  user.value = getUserFromLocalStorage();
  userSquadron = user.value.squadron?.name || '';
  userBattalion = user.value.squadron?.battalion.name || '';
  fetchData();
});


function executeDeleteAction() {
  if (confirmDeleteDialog.action) {
    confirmDeleteDialog.action();
  }
}

</script>

<style scoped>
.user-info-create-container {
  margin-bottom: 20px;
}

.table-container {
  overflow-x: auto;
  white-space: nowrap; /* Prevent text wrapping in table cells */
}

.v-data-table {
  min-width: 600px; /* Set a minimum width for the table */
}

@media (max-width: 768px) {
  .user-info-create-container {
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .v-text-field {
    font-size: 14px; /* Smaller font for text fields */
  }

  .v-text-field input {
    padding: 8px; /* Reduced padding inside text fields */
  }

  .v-btn.custom-btn {
    padding: 10px 0;
    font-size: 12px; /* Smaller font size on buttons */
  }

  .button-group {
    margin-top: 10px; /* Adjust spacing for mobile */
  }

  .table-container {
    margin-top: 20px; /* Add spacing above the table on smaller screens */
  }
}

.v-btn.custom-btn {
  font-weight: bold;
  background-color: #061A28;
  color: white;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 12px;
  text-align: center;
  line-height: 1.2;
  white-space: normal;
  max-width: 150px;
  min-height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.v-btn.custom-btn.active-tab {
  background-color: #075a75 !important;
  color: #ffffff !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3) !important;
}

.button-explanation {
  font-size: 12px;
  color: #555;
  margin-top: 4px;
  text-align: center;
}

.chat-names-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.chat-name-chip {
  margin: 2px 0;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
