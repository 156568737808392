<template>
  <v-app>
    <v-app-bar app elevation="4">
      <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>
      <v-toolbar-title>A2</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="logout" color="primary" class="custom-btn">Вихід</v-btn>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" permanent>
      <v-list dense>
        <v-list-item v-for="tab in accessibleTabs" :key="tab.name" :to="{ name: tab.name }" @click="closeDrawer">
            <v-icon>{{ tab.icon }}</v-icon>
          <v-list-item-title>{{ tab.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "@/services/store";

// User state
const drawer = ref(false);
const user = ref(JSON.parse(localStorage.getItem('user')));
const router = useRouter();
const route = useRoute();

// Redirect immediately based on the user's role
const getDefaultRoute = () => {
  if (!user.value) return null;
  const isAdmin = user.value.role.includes('admin');

  if (user.value.role === 'stat') {
    return 'Stats';
  } else if (isAdmin) {
    return 'Event';
  } else {
    return 'Event';
  }
};

const defaultRoute = getDefaultRoute();
if (route.name !== defaultRoute && defaultRoute) {
  router.replace({ name: defaultRoute });
}

// Toggle the drawer
const toggleDrawer = () => {
  drawer.value = !drawer.value;
};

// Close the drawer
const closeDrawer = () => {
  drawer.value = false;
};

// Logout the user
const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('access_token');

  useStore().clearAll();
  router.push({ name: 'Login' });
};

// Determine if the user has access to a tab
const hasAccess = (tabName) => {
  if (!user.value) return false;

  const isAdmin = user.value.role.includes('admin');

  switch (tabName) {
    case 'Event':
      return user.value.role !== 'stat';
    case 'Stats':
      return user.value.role === 'stat' || isAdmin;
    case 'Admin':
      return isAdmin;
    default:
      return false;
  }
};

// Define the accessible tabs based on the user's access level
const accessibleTabs = computed(() => {
  const tabs = [
    { name: 'Event', title: 'Події', icon: 'mdi-calendar' },
    { name: 'Stats', title: 'Статистика', icon: 'mdi-chart-bar' },
    { name: 'Admin', title: 'Адміністрування', icon: 'mdi-account-cog' }
  ];

  return tabs.filter(tab => hasAccess(tab.name));
});

</script>

