import { createRouter, createWebHistory } from 'vue-router';
import DashboardView from '@/components/DashboardView.vue';
import MapViewPage from '@/components/MapViewPage.vue';
import StatsView from '@/components/StatsView.vue';
import AdminView from '@/components/AdminView.vue';
import LoginView from '@/components/LoginView.vue';
import EventTypeSelection from '@/components/EventTypeSelection.vue';

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginView
    },
    {
        path: '/dashboard',
        component: DashboardView,
        children: [
            {
                path: '',
                redirect: { name: 'Event' }
            },
            {
                path: 'event',
                name: 'Event',
                component: MapViewPage,
                children: [
                    {
                        path: 'event-type/:positionId',
                        name: 'EventTypeSelection',
                        component: EventTypeSelection
                    }
                ]
            },
            {
                path: 'stats',
                name: 'Stats',
                component: StatsView
            },
            {
                path: 'admin',
                name: 'Admin',
                component: AdminView
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach(async (to) => {
    const isAuthenticated = !!localStorage.getItem('user');

    if (
        // make sure the user is authenticated
        !isAuthenticated &&
        // ❗️ Avoid an infinite redirect
        to.name !== 'Login'
    ) {
        // redirect the user to the login page
        return { name: 'Login' }
    }
})

export default router;
