import Battalion from './Battalion';

class Squadron {
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.battalionId = data.battalionId;
        this.isService = data.isService;
        this.battalion = this.battalionId ? new Battalion(data.battalion) : null;
    }
}

export default Squadron;