<template>
  <div class="slider-container" @click="onSliderClick">
    <div class="slider-track"></div>
    <div class="slider-ticks">
      <div
          v-for="tick in tickMarks"
          :key="tick.value"
          class="slider-tick"
          :style="{ left: `${calculatePosition(tick.value)}%` }"
      >
        <span class="tick-label">{{ tick.label }}</span>
      </div>
    </div>
    <div
        class="slider-thumb"
        :style="{ left: `${thumbPosition}%` }"
        @mousedown="startDrag"
        @touchstart="startDrag"
    ></div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted, onUnmounted } from "vue";

export default {
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    tickMarks: {
      type: Array,
      default: () => [
        { value: 0, label: '0' },
        { value: 50, label: '50' },
        { value: 200, label: '200' },
        { value: 500, label: '500' },
        { value: 1500, label: '1500' },
        { value: 3000, label: '3000' },
        { value: 5000, label: '5000' },
      ],
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const thumbPosition = ref(0);
    const dragging = ref(false);
    let sliderRect = null;

    const minValue = computed(() => props.tickMarks[0].value);
    const maxValue = computed(() => props.tickMarks[props.tickMarks.length - 1].value);
    const range = computed(() => maxValue.value - minValue.value);

    // Update thumb position based on the modelValue
    const calculateThumbPosition = () => {
      thumbPosition.value = ((props.modelValue - minValue.value) / range.value) * 100;
    };

    // Calculate the left position for a tick based on its value
    const calculatePosition = (value) => {
      return ((value - minValue.value) / range.value) * 100;
    };

    // Calculate and emit the value based on the thumb's position
    const updateValueFromPosition = (clientX) => {
      if (!sliderRect) return;
      const relativePosition = clientX - sliderRect.left;
      const percentPosition = Math.max(0, Math.min(relativePosition / sliderRect.width, 1));
      const newValue = Math.round(percentPosition * range.value + minValue.value);
      emit('update:modelValue', newValue);
    };

    // Start dragging the thumb
    const startDrag = (event) => {
      dragging.value = true;
      document.addEventListener('mousemove', onDrag);
      document.addEventListener('mouseup', stopDrag);
      document.addEventListener('touchmove', onDrag);
      document.addEventListener('touchend', stopDrag);

      const clientX = event.touches ? event.touches[0].clientX : event.clientX;
      updateValueFromPosition(clientX);
    };

    // Update the thumb position while dragging
    const onDrag = (event) => {
      if (!dragging.value) return;
      const clientX = event.touches ? event.touches[0].clientX : event.clientX;
      updateValueFromPosition(clientX);
    };

    // Stop dragging the thumb
    const stopDrag = () => {
      dragging.value = false;
      document.removeEventListener('mousemove', onDrag);
      document.removeEventListener('mouseup', stopDrag);
      document.removeEventListener('touchmove', onDrag);
      document.removeEventListener('touchend', stopDrag);
    };

    // Handle clicks on the slider track
    const onSliderClick = (event) => {
      updateValueFromPosition(event.clientX);
    };

    // Recalculate thumb position when modelValue changes
    watch(() => props.modelValue, calculateThumbPosition);

    // Cache slider dimensions on mount
    onMounted(() => {
      sliderRect = document.querySelector('.slider-container').getBoundingClientRect();
      calculateThumbPosition();
    });

    // Cleanup on unmount
    onUnmounted(() => {
      document.removeEventListener('mousemove', onDrag);
      document.removeEventListener('mouseup', stopDrag);
      document.removeEventListener('touchmove', onDrag);
      document.removeEventListener('touchend', stopDrag);
    });

    return {
      thumbPosition,
      startDrag,
      calculatePosition,
      onSliderClick,
    };
  },
};
</script>

<style scoped>
.slider-container {
  position: relative;
  width: 100%;
  height: 40px;
  margin: 20px 0;
  cursor: pointer;
}

.slider-track {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 4px;
  background-color: #ddd;
  transform: translateY(-50%);
}

.slider-ticks {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.slider-tick {
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: #555;
}

.tick-label {
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  font-size: 12px;
  margin-top: 4px;
  white-space: nowrap;
}

.slider-thumb {
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  background-color: #333;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
</style>
