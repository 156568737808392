import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { createPinia } from 'pinia';  // Import Pinia
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import './styles.scss';
import { VTreeview } from 'vuetify/labs/VTreeview'; // Import the Labs component

const vuetify = createVuetify({
    components: {
        ...components,
        VTreeview, // Add this to make it work
    },
    directives,
});

const pinia = createPinia();

createApp(App)
    .use(router)
    .use(vuetify)
    .use(pinia)  // Use Pinia
    .mount('#app');
