import axios from 'axios';
import router from '../router';
import { useStore } from '@/services/store';

function loadEnv() {
    return fetch('/env.js')
        .then((response) => response.text())
        .then((data) => {
            eval(data);
        })
        .catch((error) => {
            console.error('Failed to load env.js:', error);
        });
}

const axiosClientPromise = loadEnv().then(() => {
    const baseURL = window.env?.VUE_APP_IP || 'http://localhost:3000';

    const axiosClient = axios.create({
        baseURL: `${baseURL}/api`,
        headers: { 'Content-Type': 'application/json' },
    });

    axiosClient.interceptors.request.use(config => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }, error => {
        return Promise.reject(error);
    });

    axiosClient.interceptors.response.use(response => response, error => {
        if (error.response.status === 401) {
            localStorage.removeItem('access_token');
            const store = useStore();
            store.clearAll();
            router.push({ name: 'Login', query: { sessionExpired: true } });
        }
        return Promise.reject(error);
    });

    return axiosClient;
});

export class ApiClient {
    constructor(axiosClientPromise) {
        this.axiosClientPromise = axiosClientPromise;
    }

    async getAxiosClient() {
        return await this.axiosClientPromise;
    }

    async getSquadrons() {
        const client = await this.getAxiosClient();
        return client.get('/squadrons');
    }

    async createSquadron(data) {
        const client = await this.getAxiosClient();
        return client.post('/squadrons', data);
    }

    async editSquadron(id, data) {
        const client = await this.getAxiosClient();
        return client.patch(`/squadrons/${id}`, data);
    }

    async removeSquadron(id) {
        const client = await this.getAxiosClient();
        return client.delete(`/squadrons/${id}`);
    }

    async getBatallions() {
        const client = await this.getAxiosClient();
        return client.get('/battalions');
    }

    async createBatallion(data) {
        const client = await this.getAxiosClient();
        return client.post('/battalions', data);
    }

    async editBatallion(id, data) {
        const client = await this.getAxiosClient();
        return client.patch(`/battalions/${id}`, data);
    }

    async removeBatallion(id) {
        const client = await this.getAxiosClient();
        return client.delete(`/battalions/${id}`);
    }

    async getPositions() {
        const client = await this.getAxiosClient();
        return client.get('/positions');
    }

    async createPosition(data) {
        const client = await this.getAxiosClient();
        return client.post('/positions', data);
    }

    async editPosition(id, data) {
        const client = await this.getAxiosClient();
        return client.patch(`/positions/${id}`, data);
    }

    async removePosition(id) {
        const client = await this.getAxiosClient();
        return client.delete(`/positions/${id}`);
    }

    async getAllUsers() {
        const client = await this.getAxiosClient();
        return client.get('/users');
    }

    async createUser(data) {
        const client = await this.getAxiosClient();
        return client.post('/users', data);
    }

    async editUser(id, data) {
        const client = await this.getAxiosClient();
        return client.patch(`/users/${id}`, data);
    }

    async removeUser(id) {
        const client = await this.getAxiosClient();
        return client.delete(`/users/${id}`);
    }

    async getEvents() {
        const client = await this.getAxiosClient();
        return client.get('/events');
    }

    async createEvent(data) {
        const client = await this.getAxiosClient();
        return client.post('/events', data);
    }

    async login(params) {
        const client = await this.getAxiosClient();
        return client.post('/auth/login', params, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async getMe() {
        const client = await this.getAxiosClient();
        return client.get('/users/me');
    }
}

export default new ApiClient(axiosClientPromise);
